<template>
  <b-overlay :show="show" rounded="sm" class="d-flex align-items-center justify-content-center">
    <b-container >
      <b-row >
        <b-col cols="12" md="6" class="my-3">
          <ValidationObserver v-slot="{ invalid, handleSubmit }">
            <b-form @submit.prevent="handleSubmit(makeRegister)">
            <!-- Paso 1: Correo -->
            <b-card class="pl-md-3 pr-md-3 " v-if="step === 1">
              <b-col md="12">
                <b-card-title> {{ $t('register.title') }} </b-card-title>
              </b-col>
              <b-col class="my-4">
                <b-card-text class="d-inline-block " style="font-size: 14px;">
                  {{ $t('register.text') }}
                  <b-link :to="`/${$i18n.locale}/principal-page/login`"> {{ $t('register.link') }} </b-link>
                </b-card-text>
              </b-col>
              <b-col class="my-4">
                <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                  <b-form-group
                    :label="$t('register.label-email')"
                    label-for="correo"
                    style="font-size: 14px"
                  >
                  <b-form-input
                    id="correo"
                    v-model="email"
                    trim
                    class="input-custom "
                    :class="{ 'is-invalid': errors[0] }"
                    @keydown.enter.prevent
                    ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="12" class="text-right">
                <b-button
                  variant="primary"
                  @click="nextStep"
                  :disabled="invalid"
                  >{{ $t('btn') }}
                </b-button>
              </b-col>
              <b-card-text class="text-center my-4"> {{ $t('option') }} </b-card-text>
              <b-col cols="12" class="d-flex justify-content-center">
              <!--   <b-button block class="b-button" style="background-color: #1A4789;" disabled>
                  <img src="@/assets/icons/icons-facebook.svg" alt="Icono de Facebook" class="img-fluid" width="38" />
                  <span class="mx-auto">{{ $t('btn-fb') }}</span>
                </b-button> -->
                <b-button block class="b-button text-dark" style="background-color: #fff;" @click="signUpWithGoogle">
                  <img src="@/assets/icons/icons-google.svg" alt="Icono de Google" class="img-fluid" width="38" />
                  <span class="mx-auto">{{ $t('btn-google') }}</span>
                </b-button>
              </b-col>
            </b-card>
            <!-- Paso 2: Nombre, Ocupación y Contraseña -->
            <b-card class="px-3 py-5" v-if="step === 2">
              <ValidationProvider
                name="name"
                rules="required|min:3|max:70"
                lazy 
                v-slot="{ errors }"
                >
                <b-form-group :label="$t('register.label-name')" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="name"
                    trim
                    class="input-custom"
                    :class="{ 'is-invalid': errors[0] }"
                    :placeholder="$t('register.placeholder-name')"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                <b-form-group :label="$t('register.label-email-2')" label-for="correo" style="font-size: 14px;">
                  <b-form-input id="correo" v-model="email" trim class="input-custom" :placeholder="$t('register.placeholder-email')"
                    :class="{ 'is-invalid': errors[0] }"></b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider
                name="occupation"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group :label="$t('register.select-occupation')" label-for="role">
                  <b-form-select
                    id="role"
                    v-model="role"
                    :options="[
                      { text: $t('register.select-options.please-select'), value: null, disabled: true },
                      { text: $t('register.select-options.teacher'), value: 'teacher' },
                      { text: $t('register.select-options.student'), value: 'student' },
                    ]"
                    :class="{ 'is-invalid': errors[0] }"
                  ></b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider
                name="password"
                rules="required|password|max:50|min:8"
                v-slot="{ errors }"
              >
                <b-form-group :label="$t('register.label-pass')" label-for="password"  class="inner-addon right-addon">
                  <b-form-input
                    id="password"
                    v-model="password"
                    trim
                    class="input-custom"
                    :class="{ 'is-invalid': errors[0] }"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="*********"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  <b-input-group-append>
                    <span
                      @click="showPassword = !showPassword"
                      class="left-addon right-addon far fas eye-show"
                      :class="{ hide: showPassword }"
                    >
                      <eye-icon />
                    </span>
                    <span
                      class="left-addon right-addon far fas eye-hide"
                      @click="showPassword = !showPassword"
                      :class="{ hide: !showPassword }"
                    >
                      <eye-off-icon />
                    </span>
                  </b-input-group-append>
                </b-form-group>
              </ValidationProvider>
              <!-- <b-button @click="prevStep">Atrás</b-button> -->
              <b-card-text class="text-center">
                <b-button
                  pill
                  class="pt-2 pb-2 pl-4 pr-4 btn-create"
                  style="background-color: #76b8f1"
                  type="submit"
                  :disabled="invalid"
                  >{{ $t('register.btn') }}
                </b-button>
              </b-card-text>
            </b-card>
            </b-form>
          </ValidationObserver>    
        </b-col>
        <b-col cols="12" md="6"  v-if="step === 1" class="my-3">
          <div class="ml-md-5 ml-lg-5">
            <Card :customClass="'shadow '" >
              <img src="@/assets/gifs/img_signin-signup.gif" class="img-fluid" width="300" alt="Robot animate">
            </Card>
          </div>
          <div class="mt-5">
            <Blockquote></Blockquote>
          </div>
        </b-col>
        <b-col cols="12" md="6"  v-if="step === 2" class="my-3">
          <div class="ml-md-5 ml-lg-5">
            <Card :customClass="'shadow'" >
              <img src="@/assets/gifs/image_signup_step2.gif" class="mx-auto d-block" width="300" height="250" alt="Robot animate register paso 2">
            </Card>
          </div>
          <div class="mt-5">
            <Blockquote></Blockquote>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>
 
<script>
import EyeIcon from "vue-material-design-icons/Eye.vue";
import EyeOffIcon from "vue-material-design-icons/EyeOff.vue";
import Swal from "sweetalert2";
import Card from '@/components/shared/Card.vue';
import Blockquote from '@/components/shared/Blockquote.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    Card,
    Blockquote,
    EyeIcon,
    EyeOffIcon
  },
  data() {
    return {
      show: false,
      step: 1,
      email: '',
      password: '',
      name: '',
      role: null,
      showPassword: false,
    };
  },
  methods: {
  ...mapActions('auth', ['register','signInWithGoogle']),
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    async signUpWithGoogle() {
      try {
        await this.signInWithGoogle();
        this.$router.push(`/${this.$i18n.locale}/home`)
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Register With Google Failed',
          text: error.message,
        });
      }
    },
    async makeRegister() {
      this.show = true;
      const userData = {
        name: this.name,
        email: this.email,
        role: this.role
      };
      try {
        await this.register({ email: this.email, password: this.password, userData });
        this.$router.push(`/${this.$i18n.locale}/home`)
      } catch (error) {
        this.show = false;
        Swal.fire({
          icon: 'error',
          title: 'Register Failed',
          text: error.message,
        });
      }
    },
  },
 };
</script>
<style scoped>
.input-custom {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
}
.b-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 345px;
}
.btn-secondary {
  border-color: #eee;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  border-color: #eee;
}
.btn-secondary {
  border-color: #76b8f1;
}
.btn-create .btn-secondary:hover {
  border-color: rgb(118, 184, 241);
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  border-color: rgb(118, 184, 241);
}
.custom-card-login {
  height: 344px;
}
.hide {
  display: none;
}
.inner-addon {
  position: relative;
}
.inner-addon .far,
.inner-addon .fas {
  position: absolute;
  padding: 9px 6px;
  z-index: 9;
  cursor: pointer;
}
.left-addon .far,
.left-addon .fas {
  left: 0px;
}
.right-addon .far,
.right-addon .fas {
  right: 0px;
  top: 25px;
}
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}
input {
  font-size: 14px;
}
input::placeholder {
  font-size: 14px;
}
select {
  font-size: 14px;
}

select option {
  font-size: 14px;
}
</style>
 