<template>
  <b-navbar toggleable style="background-color: #0b1427" type="dark" class="p-0">
    <b-navbar-brand v-if="hideCentralLogo" class="pl-3">
      <div class="d-inline navigate" @click="navigateToHome">
        <img src="@/assets/imgs/logo.png" class="img-fluid" alt="Logo Inventech" width="60">
      </div>
      <span class="text-white pl-4" v-if="selectedProject">{{ selectedProject.name }}</span>
    </b-navbar-brand>
      <router-link :to="`/${$i18n.locale}/principal-page`" class="mx-auto main-logo" v-if="!hideCentralLogo && !isAuthenticated">
      <img
        :src="require('@/assets/imgs/logoInventech.png')"
        width="180"
        class="img-fluid"
      />
    </router-link>
    <router-link :to="`/${$i18n.locale}/principal-page`" class="mx-auto main-logo-session" v-if="!hideCentralLogo && isAuthenticated">
      <img
        :src="require('@/assets/imgs/logoInventech.png')"
        width="180"
        class="img-fluid"
      />
    </router-link>
    <div class="d-flex justify-content-between navbar-btn" >
      <!-- <img src="@/assets/svg/s.svg" class="img-fluid px-2" alt="Logo Inventech" >
      <img src="@/assets/svg/v.svg" class="img-fluid px-3" alt="Logo Inventech" >  -->
      <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none dropdown-lang" no-caret :title="$t('navbar.lang')">
        <template #button-content>
          <img src="@/assets/imgs/iconoIdiomas.png" class="img-fluid " alt="Lang icon">
        </template>
        <b-dropdown-item @click.prevent="setLocale('pt')">PT</b-dropdown-item>
        <b-dropdown-item @click.prevent="setLocale('es')">ES</b-dropdown-item>
      </b-dropdown>
     <!--  <b-button variant="link" v-b-toggle.sidebar-right v-if="isAuthenticated">
        <img src="@/assets/imgs/user/user.png" class="img-fluid img-user" alt="Lang icon" width="35">
      </b-button> -->
      <UserProfile v-if="isAuthenticated" />
<!--       <b-navbar-toggle class="toogleable " target="navbar-toggle-collapse" v-if="isAuthenticated" @click="closeComponents">
        <template>
          <img :src="require('@/assets/icons/menu.svg')" title="Hamburger Menu Icon" />
        </template>
      </b-navbar-toggle> -->
    </div>
  <!-- <b-collapse id="navbar-toggle-collapse" is-nav class="navbar-collapse expand">
      <b-navbar-nav class="text-li">
        <b-nav-item class="mt-3"  @click="closeToggle" to="/principal-page"
          >{{ $t('navbar.main') }}</b-nav-item
        >
        <b-nav-item class="mt-3"  @click="closeToggle" to="/projects"
          >{{ $t('navbar.projects') }}</b-nav-item
        >
        <b-nav-item class="mt-3" @click="onLogout"
          >{{ $t('navbar.logout') }}</b-nav-item
        >
      </b-navbar-nav>
    </b-collapse> -->
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from "@/main.js";
import UserProfile from "@/views/user/UserProfile.vue";
import Swal from "sweetalert2";
export default {
  components: {
    UserProfile
  },
  data() {
    return {
      hideCentralLogo: false,
      isProjectName: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('projects', ['selectedProject']),
  },
  watch: {
    "$route.fullPath": {
      handler: function(newPath) {
        // Verifica si la ruta actual es la página de perfil
        this.hideCentralLogo = newPath.includes("/create-project/") ||
          newPath.includes("/board") ||
          newPath.includes("/projects") ||
          newPath.includes("/home");
        this.isProjectName = newPath.includes("/board/");
      },
      immediate: true,
    },
   '$route.params.id': 'loadProject'
  },
  created() {
    this.loadProject();
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('projects', ['fetchProjectById','deselectProject']),
    async loadProject() {
      const projectId = this.$route.params.id;
      if (projectId) {
        await this.fetchProjectById(projectId);
      } else {
        this.deselectProject();
      }
    },
    setLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$router.push({
          params: { lang: locale }
        });
      }
    },
    navigateToHome() {
      // Redirige a la vista de home
      if (this.$route.path != `/${this.$i18n.locale}/home`) {
        this.$router.push(`/${this.$i18n.locale}/home`);
      }
    },
    closeComponents() {
      // Cierra el b-modal si está abierto
      EventBus.$emit('close-modal');
      EventBus.$emit('close-sidebar');
      // Aquí puedes agregar más lógica para cerrar otros componentes si es necesario
    },
    async onLogout() {
      const result = await Swal.fire({
        title: this.$t('logout.title'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "secondary",
        confirmButtonText: this.$t('logout.yes'),
        cancelButtonText: this.$t('logout.no'),
      });

      if (result.isConfirmed) {
        try {
          await this.logout();
          this.$router.push(`/${this.$i18n.locale}/principal-page`);
        } catch (error) {
          console.error('Failed to logout:', error);
          Swal.fire(
            'Error',
            'Hubo un problema al cerrar la sesión. Por favor, inténtalo de nuevo.',
            'error'
          );
        }
      }
      this.closeToggle();
    },
    closeToggle() {
      // Cierra manualmente el menú colapsado
      this.$root.$emit("bv::toggle::collapse", "navbar-toggle-collapse");
    },
  },
};
</script>
<style>
.navbar-btn .btn:focus, .btn.focus {
  box-shadow: 0 0 0 0;
}
</style>
<style scoped>
.expand {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 25%;
  background-color: #0b1427;
  z-index: 1034; /* Superponerse a otros elementos */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Evita el desplazamiento de fondo */
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #76b8f1;
}
.navbar-collapse {
  transition: height 0.1s ease-in-out, opacity 0.1s ease-in-out;
}
.text-li {
  font-size: 32px;
  line-height: 46.88px;
  color: #ffffff;
}
.toogleable {
  border: none;
  z-index: 1035; 
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.navigate {
  cursor: pointer;
}
.main-logo {
  padding-left: 120px;
}
.main-logo-session {
  padding-left: 15%;
}
</style>
