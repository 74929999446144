<!-- CreateProject.vue -->
<template>
  <div class="d-flex align-items-center justify-content-center">
    <b-container fluid>
      <b-row>
        <b-col>
          <h4 class="mt-2 mx-5"> {{ $t('create-project-message', { projectType: $t(`project-types.${formattedProjectType}`) }) }}</h4>
          <!-- Contenido específico del proyecto según el valor de $route.params.projectType -->
          <template v-if="$route.params.projectType === 'robotica'">
            <!-- Contenido para proyectos de robotica -->
            <Card>
              <b-col v-for="item of roboticaImg" :key="item.index">
                <b-card  class="my-2 section card-container text-center card-category py-4" @click="navigateProjectPage(item.index, roboticaImg)">
                  <div class="custom-button my-4 py-2 ">
                    {{ $t(item.nameProject) }}
                  </div>
                    <img :src="item.url" :alt="item.textAlt" class="img-fluid" width="200">
                  </b-card>
                  <b-card-text class="text-center">{{ $t(item.title) }}</b-card-text>
              </b-col>
            </Card>
          </template>
          <template v-else-if="$route.params.projectType === 'iot'">
            <!-- Contenido para proyectos de IoT -->
            <Card>
              <b-col v-for="item of iotImg" :key="item.index">
                <b-card  class="my-2 section card-container text-center card-category py-4" @click="navigateProjectPage(item.index, iotImg)">
                  <div class="custom-button my-4 py-2">
                      {{ $t(item.nameProject) }}
                  </div>
                    <img :src="item.url" :alt="item.textAlt" class="mx-auto d-block" width="200" height="200">
                  </b-card>
                  <b-card-text class="text-center">{{ $t(item.title) }}</b-card-text>
              </b-col>
            </Card>
          </template>
          <template v-else-if="$route.params.projectType === 'videogames'">
            <!-- Contenido para proyectos de videojuegos -->
            <Card>
              <b-col v-for="item of videogamesImg" :key="item.index">
                <b-card  class="my-2 section card-container text-center card-category py-4" @click="navigateProjectPage(item.index, videogamesImg)">
                  <div class="custom-button my-4 py-2">
                      {{ $t(item.nameProject) }}
                  </div>
                    <img :src="item.url" :alt="item.textAlt" class="mx-auto d-block" width="200" height="200">
                  </b-card>
                  <b-card-text class="text-center">{{ $t(item.title) }}</b-card-text>
              </b-col>
            </Card>
          </template>
          <template v-else-if="$route.params.projectType === 'ia'">
            <!-- Contenido para proyectos de IA -->
            <Card>
              <b-col v-for="item of iaImg" :key="item.index">
                <b-card  class="my-2 section card-container text-center card-category py-5" @click="navigateProjectPage(item.index, iaImg)">
                  <div class="custom-button mb-5 py-2">
                      {{ $t(item.nameProject) }}
                  </div>
                    <img :src="item.url" :alt="item.textAlt" class="mx-auto d-block" width="139" height="139">
                  </b-card>
                  <b-card-text class="text-center">{{ $t(item.title) }}</b-card-text>
              </b-col>
            </Card>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Card from '@/components/CardProject.vue';
export default {
  components: {
    Card
  },
  data() {
    return {
      roboticaImg: [
        { index: 1, nameProject: 'robotics-project.cards.name1', title: 'cards-project-types.title1', url: this.getSrcRobotica('project1'), textAlt: 'Img Project1' },
        { index: 2, nameProject: 'robotics-project.cards.name2', title: 'cards-project-types.title2', url: this.getSrcRobotica('project2'), textAlt: 'Img Project2' },
        { index: 3, nameProject: 'robotics-project.cards.name3', title: 'cards-project-types.title3', url: this.getSrcRobotica('project3'), textAlt: 'Img Project3' },
        { index: 4, nameProject: 'robotics-project.cards.name4', title: 'cards-project-types.title4', url: this.getSrcRobotica('project4'), textAlt: 'Img Project4' },
      ],
      iotImg: [
        { index: 1, nameProject: 'iot-project.cards.name1', title: 'cards-project-types.title1', url: this.getSrcIot('project1'), textAlt: 'Img Project1' },
        { index: 2, nameProject: 'iot-project.cards.name2', title: 'cards-project-types.title2', url: this.getSrcIot('project2'), textAlt: 'Img Project2' },
        { index: 3, nameProject: 'iot-project.cards.name3', title: 'cards-project-types.title3', url: this.getSrcIot('project3'), textAlt: 'Img Project3' },
        { index: 4, nameProject: 'iot-project.cards.name4', title: 'cards-project-types.title4', url: this.getSrcIot('project4'), textAlt: 'Img Project4' },
      ],
      videogamesImg: [
        { index: 1, nameProject: 'videogames-project.cards.name1', title: 'cards-project-types.title1', url: this.getSrcVideoGames('project1.png'), textAlt: 'Img Project1' },
        { index: 2, nameProject: 'videogames-project.cards.name2', title: 'cards-project-types.title2', url: this.getSrcVideoGames('project2.png'), textAlt: 'Img Project2' },
        { index: 3, nameProject: 'videogames-project.cards.name3', title: 'cards-project-types.title3', url: this.getSrcVideoGames('project3.png'), textAlt: 'Img Project3' },
        { index: 4, nameProject: 'videogames-project.cards.name4', title: 'cards-project-types.title4', url: this.getSrcVideoGames('project4.png'), textAlt: 'Img Project4' },
      ],
      iaImg: [
        { index: 1, nameProject: 'ia-project.cards.name1', title: 'cards-project-types.title1', url: this.getSrcIA('no-content'), textAlt: 'Img Project1' },
        { index: 2, nameProject: 'ia-project.cards.name2', title: 'cards-project-types.title2', url: this.getSrcIA('no-content'), textAlt: 'Img Project2' },
        { index: 3, nameProject: 'ia-project.cards.name3', title: 'cards-project-types.title3', url: this.getSrcIA('no-content'), textAlt: 'Img Project3' },
        { index: 4, nameProject: 'ia-project.cards.name4', title: 'cards-project-types.title4', url: this.getSrcIA('no-content'), textAlt: 'Img Project4' },
      ],
    };
  },
  computed: {
    formattedProjectType() {
      const projectType = this.$route.params.projectType;
      // Aquí puedes agregar lógica para convertir projectType según tus necesidades
      // Por ejemplo, convertir "robotica" en "Robotica" y "iot" en "Internet de las Cosas"
      if (projectType === 'robotica') return 'robotics'
      if (projectType === 'iot') return 'iot';
      if (projectType === 'videogames') return 'videogames';
      if (projectType === 'ia') return 'ia'
      // Agrega más conversiones según sea necesario
      return projectType; // Valor predeterminado si no se encuentra una conversión
    },
  },
  methods: {
    getSrcRobotica(name) {
      const imgPath = require(`@/assets/imgs/robotica/${name}.png`)
      return imgPath
    },
    getSrcIot(name) {
      const imgPath = require(`@/assets/imgs/iot/${name}.png`)
      return imgPath
    },
    getSrcVideoGames(name) {
      const imgPath = require(`@/assets/imgs/videogames/${name}`)
      return imgPath
    },
    getSrcIA(name) {
      const imgPath = require(`@/assets/imgs/ia/${name}.png`)
      return imgPath
    },
 /*    navigateProjectPage(projectId, array){
      // Obtiene el nombre del proyecto al que se hizo clic
      const project = array.find((item) => item.title === projectId)
      // Convierte el nombre del proyecto a minúsculas y reemplaza los espacios por guiones
      const formatedNameToUrl = project.title.toLowerCase().replaceAll(" ", "-");
      // Navega a la página del proyecto
      this.$router.push({ name: 'blank', params: { projectId: formatedNameToUrl } });
    } */
    navigateProjectPage(index, array) {
      // Formar un nombre estático seguido del índice
      const projectName = `project-${index}`;
      
      // Navega a la página del proyecto
      this.$router.push({ name: 'blank', params: { projectId: projectName } });
    },
  },
};
</script>


<style scoped>
.custom-button {
  color: #fff;
  background-color: #76b8f1;
  border: 1px solid #76b8f1;
  border-radius: 22px;
  user-select: none; /* No permite la selección de texto */
  opacity: 0.8;
}
.card-category {
  border: 2px solid #76b8f1;
  border-radius: 25px;
  cursor: pointer;
}
.card-container:hover {  
  background-color: rgba(118, 184, 241, 1); 
  opacity: 0.9;
  transition: background-color 0.3s;
}
.card-container:hover .custom-button {
  background-color: #fff;
  color: #000;
}
</style>
