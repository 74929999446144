<template>
  <div id="app">
    <header>
      <NavBar />
    </header>
    <router-view />
    <FooterPage v-if="!isNewProjectPage" />
  </div>
</template>
<script>
import NavBar from "@/components/principal-page/NavBar.vue";
import FooterPage from "@/components/principal-page/FooterPage.vue";

export default {
  components: {
    NavBar,
    FooterPage,
  },
  data() {
    return {
      isOpen: false,
      isNewProjectPage: false,
    };
  },
  computed: {
    isFooterVisible() {
      return this.$store.getters.isFooterVisible;
    },
  },
  watch: {
    "$route.fullPath": {
      handler: function(newPath) {
        // Verifica si la ruta actual es la página de nuevo proyecto
        this.isNewProjectPage = newPath.includes("/board");
      },
      immediate: true,
    },
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
  font-family: "Google Sans";
  src: url('/public/GoogleSans.ttf') format("truetype");
  font-weight: 400
}

#app {
  display: grid;
  flex-direction: column;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  font-family: "Google Sans","Roboto",Arial;
  /* font-size: 14px; */
  line-height: 20px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
label {
  font-size: 14px;
}

.dropdown-menu {
  min-width: 0 !important;
}

.content-button-menu .btn-group > .btn, .btn-group-vertical > .btn {
  border: 0;
}

#sidebar-right-step {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 89px;
  right: 0;
  width: 46%;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 0px);
  border: 1px solid #ccc;
  padding: 0;
  transition: .6s ease-in-out;
  background-color: #fff !important;
  border-radius: 15px 0 0 15px;
}
#sidebar-right-step .b-sidebar-header{
  padding: 0 !important;
}
#modal-center .modal-content {
  border-radius: 25px;
}
</style>
