<template>
  <b-card class="mb-3 ml-5 mr-5">
    <b-row>
      <slot></slot>
    </b-row>
  </b-card>
</template>
  
<script>
export default {};
</script>
<style scoped></style>
  