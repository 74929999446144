import projectData from '../data/projectData';

const projectService = {
  getProjectsByUserId: async (userId) => {
    try {
      const projects = await projectData.getProjectsByUserId(userId);
      return projects;
    } catch (error) {
      console.error('Error getting projects:', error);
      throw error;
    }
  },
  createProject: async (project) => {
    try {
      const newProject = await projectData.createProject(project);
      return newProject;
    } catch (error) {
      console.error('Error creating project:', error);
      throw error;
    }
  },
  deleteProject: async (projectId) => {
    try {
      await projectData.deleteProject(projectId);
    } catch (error) {
      console.error('Error deleting project:', error);
      throw error;
    }
  },
  updateProject: async (projectId, updateData) => {
    try {
      const updatedProject = await projectData.updateProject(projectId, updateData);
      return updatedProject;
    } catch (error) {
      console.error('Error updating project:', error);
      throw error;
    }
  },
  getProjectById: async (userId, projectId) => {
    try {
      const project = await projectData.getProjectById(userId, projectId);
      return project;
    } catch (error) {
      console.error('Error getting project by ID:', error);
      throw error;
    }
  },
  addComponentToProject: async (projectId, component) => {
    try {
      await projectData.addComponentToProject(projectId, component);
    } catch (error) {
      console.error('Error adding component to project:', error);
      throw error;
    }
  },
  // Nueva función para contar componentes
  async countComponentsByPrefix(userId, projectId, prefix) {
    try {
      const project = await projectData.getProjectById(userId, projectId);
      const projectComponents = project.components || [];
  
      // Contar componentes cuyo id_local empieza con el prefijo
      const count = projectComponents.filter(component =>
        component.id_local.startsWith(prefix)
      ).length;
  
      return count
    } catch (error) {
      console.error('Error counting components by prefix:', error);
      throw error;
    }
  },

  removeComponentFromProject: async (projectId, idLocal) => {
    try {
      await projectData.removeComponentFromProject(projectId, idLocal);
    } catch (error) {
      console.error('Error adding component to project:', error);
      throw error;
    }
  },
  uploadImageToProject: async (file) => {
    const url = await projectData.uploadImageToProject(file);
    return url;
  },
};

export default projectService;
