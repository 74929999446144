<template>
  <div class="d-flex align-items-center justify-content-center background-container">
    <b-container>
      <b-row>
        <b-col cols="12" class="my-3">
          <h1 class="text-center">{{ $t('main.title') }}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center" cols="12">
          <div class="custom-button">
            <strong>{{ $t('main.text') }}</strong> 
          </div>
        </b-col>
      </b-row>
      <b-row  >
        <b-col cols="12" class="text-center my-4">
          <p class="pl-5 pr-5 paragraph m-0">
            {{ $t('main.paragraph') }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4" v-for="link of links" :key="link.index">
          <Card :customClass="'mx-auto shadow my-2'" :customStyle="'max-width: 19rem'">
            <img :src="link.url" width="100px" height="100px" :alt="link.textAlt">
            <b-card-text>
              {{ $t('main.card' + (link.index + 1)) }}
            </b-card-text>
          </Card>
        </b-col>
      </b-row>
      <b-row v-if="!isAuthenticated">
        <b-col sm="12" class="text-center my-3">
          <b-button pill to="principal-page/login" class="btn-inline-block m-2">
            <img src="@/assets/icons/enter.svg" class="img-fluid" width="20"  />
            {{ $t('main.btn-signin') }}
          </b-button>
          <b-button pill to="principal-page/register" class="btn-inline-block m-2">
            <img src="@/assets/icons/addUserMale.svg" class="img-fluid" width="20" />
            {{ $t('main.btn-signup') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="justify-content-center text-center" v-if="isAuthenticated">
        <b-col xl="3" sm="12" md="12" xs="12" class="my-3">
          <b-button pill to="/home" class="text-white pr-5 pl-5">
            REGRESAR
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Card from '@/components/shared/Card.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'Principal',
  components: {
    Card
  },
  data() {
    return {
      links: [
        { index: 0, url: this.getSrc('circuit-board'), textAlt: 'Img Circuito' },
        { index: 1, url: this.getSrc('html'), textAlt: 'Img Code' },
        { index: 2, url: this.getSrc('idea'), textAlt: 'Img Idea' },
      ]
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },
  methods: {
    getSrc(name) {
      const imgPath = require(`@/assets/gifs/${name}.gif`)
      return imgPath
    }
  },
};
</script>

<style scoped>
.background-container {
  background-image: url('../../assets/imgs/img_main_page.png');
  /* Ruta de la imagen de fondo */
  background-size: cover;
  /* Para ajustar la imagen al tamaño del contenedor */
  background-repeat: no-repeat;
  /* Evita la repetición de la imagen */
  background-attachment: fixed;
  /* Para que la imagen de fondo no se desplace con el contenido */
}
.btn-secondary {
  background-color: #76b8f1;
  border-color: #76b8f1;
}
.btn-secondary:hover {
  background-color: #76b8f1;
  border-color: rgb(118, 184, 241);
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: #76b8f1;
  border-color: rgb(118, 184, 241);
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0;
}
.btn-secondary:focus, .btn-secondary.focus {
  background-color: #76b8f1;
  border-color: rgb(118, 184, 241);
}
.button-up-custom {
  border-radius: 1rem;
}
.card {
  border-radius: 1.25rem;
}
.custom-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  color: #0b1427;
  border: 1px solid #0b1427;
  border-radius: 15px;
  user-select: none; /* No permite la selección de texto */
  opacity: 0.8;
}
.paragraph {
  font-size: 24px;
  line-height: 1.1;
}
</style>
