import authService from '../../businessLogic/authService';

const state = {
  user: JSON.parse(localStorage.getItem('user')) || null
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  user: (state) => state.user
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  clearUser(state) {
    state.user = null;
    localStorage.removeItem('user');
  },
};

const actions = {
  async signInWithGoogle({ commit }) {
    try {
      const user = await authService.signInWithGoogle();
      commit('setUser', user);
    } catch (error) {
      throw error;
    }
  },
  async register({ commit }, { email, password, userData }) {
    try {
      const user = await authService.register(email, password, userData);
      commit('setUser', user);
    } catch (error) {
      throw error; 
    }
  },
  async login({ commit }, { email, password }) {
    try {
      const user = await authService.login(email, password);
      commit('setUser', user);
    } catch (error) {
      throw error;
    }
  },
  async logout({ commit }) {
    await authService.logout();
    commit('clearUser');
  },
  async fetchUser({ commit }) {
    const user = await authService.getUserProfile();
    if (user) {
      commit('setUser', user);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
