<template>
  <b-overlay :show="show" :z-index="1061" rounded="sm" class="d-flex align-items-center justify-content-center">
    <b-container fluid>
      <b-row class="mt-2">
        <b-col>
          <h4 class="mt-2 ml-5">{{ $t('home.title') }}</h4>
          <b-card class="ml-5 mr-5">
            <b-card-text class="text-muted pl-2" style="font-size: 22px;">{{ $t('home.card1.title') }}</b-card-text>
            <b-row class="row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-5 row-cols-xl-5">
              <b-col>
                <b-card class="mb-2 card-container btn-custom text-center p-5" @click="showModalForCreate">
                  <span class="text-plus">+</span>
                </b-card > 
                <b-card-text class="text-center">{{ $t('home.card1.option-1') }}</b-card-text>
              </b-col>
              <b-col>
                <b-card  class="mb-2 section card-container text-center card-category" @click="navigateToCreateProject('robotica')">
                  <img src="@/assets/imgs/robotica/robotica.png" alt="Proyecto Robótica" class="rounded mx-auto d-block" width="150" height="130">
                </b-card>
                <b-card-text class="text-center">{{ $t('home.card1.option-2') }}</b-card-text>
              </b-col>
              <b-col>
                <b-card  class="mb-2 section card-container text-center card-category" @click="navigateToCreateProject('iot')">
                  <img src="@/assets/imgs/iot/iot.png" alt="Proyecto IoT" class="rounded mx-auto d-block" width="130" height="130">
                </b-card>
                <b-card-text class="text-center">{{ $t('home.card1.option-3') }}</b-card-text>
              </b-col>
              <b-col>
                <b-card  class="mb-2 section card-container text-center card-category" @click="navigateToCreateProject('videogames')">
                  <img src="@/assets/imgs/videogames/games.png" alt="Proyecto Videojuegos" class="rounded mx-auto d-block" width="150" height="130">
                </b-card>
                <b-card-text class="text-center">{{ $t('home.card1.option-4') }}</b-card-text>
              </b-col>
              <b-col>
                <b-card  class="mb-2 section card-container text-center card-category" @click="navigateToCreateProject('ia')">
                  <img src="@/assets/imgs/ia/ia.png" alt="Proyecto IA" class="rounded mx-auto d-block" width="130" height="130">
                </b-card>
                <b-card-text class="text-center">{{ $t('home.card1.option-5') }}</b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <div class="d-flex justify-content-between">
            <h4 class="mt-4 ml-5">{{ $t('projects.title') }}</h4>
            <b-button class="mt-4 pr-5" variant="link" @click="navigateToAllProjects">Ver todos</b-button>
          </div>
          <b-card class="ml-5 mr-5 mb-3" v-if="firstFiveProjects.length > 0">
            <b-row class="row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-5 row-cols-xl-5">
              <b-col v-for="project in firstFiveProjects" :key="project.id" @click="selectProjectAndGoToCanvas(project.id)">
                <b-card class="mb-2 card-container section">
                  <b-card-text class="text-center p-0">
                    <div class="project-img-container">
                      <img :src="project.imageUrl || noImage" alt="Imagen del proyecto" class="project-img-default" />
                    </div>
                  </b-card-text>
                  <div class="context-button">
                    <b-dropdown size="lg" dropright variant="none" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <dots-horizontal-icon />
                      </template>
                      <b-dropdown-item @click.stop="showModalForUpdateName(project)">Cambiar nombre</b-dropdown-item>
                      <b-dropdown-item @click.stop="showModalForUpdateImage(project)">Cambiar imagen</b-dropdown-item>
                      <b-dropdown-item @click.stop="removeProject(project.id)">Eliminar</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-card>
                <b-card-text class="text-center">{{ project.name }}</b-card-text>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="ml-5 mr-5 mb-3 text-center" v-else>
            <h3>No hay proyectos creados aún</h3>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      v-model="showModal"
      centered
      id="modal-center"
      :title="
        isUpdateMode
          ? updateType === 'name'
            ? 'Cambiar el nombre del proyecto'
            : 'Cambiar la imagen del proyecto'
          : 'Crear Nuevo Proyecto'
      "
    >
      <!-- Formulario para crear o actualizar -->
      <b-form @submit.prevent="isUpdateMode ? handleUpdate : insertProject">
        <!-- Campo para el nombre -->
        <b-form-group
          v-if="!isUpdateMode || updateType === 'name'"
          :label="$t('home.modal.label-name')"
          label-for="name-input"
        >
          <b-form-input
            id="name-input"
            v-model="newProjectName"
            :placeholder="$t('home.modal.placeholder-name')"
            required
          ></b-form-input>
        </b-form-group>

        <!-- Campo para la categoría (solo al crear) -->
        <b-form-group
          v-if="!isUpdateMode"
          :label="$t('home.modal.label-select')"
          label-for="categories"
        >
          <b-form-select
            id="categories"
            v-model="selectedCategory"
            :options="categories"
          ></b-form-select>
        </b-form-group>

        <!-- Campo para la imagen (al crear o actualizar la imagen) -->
        <b-form-group
          v-if="!isUpdateMode || updateType === 'image'"
          :label="'Imagen del proyecto'"
          label-for="image-input"
        >
          <b-form-file
            accept="image/jpeg, image/png"
            id="image-input"
            @change="handleFileUpload"
            placeholder="Selecciona una imagen..."
          ></b-form-file>
        </b-form-group>
      </b-form>

      <!-- Botones en el footer -->
      <template v-slot:modal-footer>
        <div class="w-100">
          <button
            type="button"
            @click="isUpdateMode ? handleUpdate() : insertProject()"
            class="btn btn-primary float-right m-1"
          >
            {{ isUpdateMode ? "Actualizar" : $t("home.modal.btn-ok") }}
          </button>
          <button @click="closeModal" class="btn btn-secondary float-right m-1">
            {{ $t("home.modal.btn-cancel") }}
          </button>
        </div>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DotsHorizontalIcon from "vue-material-design-icons/DotsHorizontal.vue";
import noImage from '@/assets/svg/no-image.svg';
export default {
  name: 'HomeView',
  components: {
    DotsHorizontalIcon,
  },
  data() {
    return {
      show: false,
      showModal: false,
      showModalImage: false,
      isUpdateMode: false,
      currentProject: null,
      newProjectName: '',
      categories: [
        { value: 'robotics', text: this.$t('home.modal.option1') },
        { value: 'iot', text: this.$t('home.modal.option2') },
        { value: 'videogames', text: this.$t('home.modal.option3') },
        { value: 'ia', text: this.$t('home.modal.option4') },
        { value: 'other', text: this.$t('home.modal.option5') }
      ],
      selectedCategory: null,
      imageFile: null,
      noImage,
      updateType: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('projects', ['projects']),
    firstFiveProjects() {
      return this.projects.slice(0, 5);
    }
  },
  methods: {
    ...mapActions('projects', ['fetchProjects', 'createProject', 'deleteProject', 'updateProject', 'selectProject', 'uploadProjectImage']),
    showModalForCreate() {
      this.isUpdateMode = false;
      this.newProjectName = '';
      this.selectedCategory = null;
      this.showModal = true;
    },
    // Método para mostrar el modal en modo de actualización de nombre
    showModalForUpdateName(project) {
      this.isUpdateMode = true;
      this.updateType = 'name'; // Se actualiza el nombre
      this.currentProject = project;
      this.newProjectName = project.name;
      this.showModal = true;
    },

    // Método para mostrar el modal en modo de actualización de imagen
    showModalForUpdateImage(project) {
      this.isUpdateMode = true;
      this.updateType = 'image'; // Se actualiza la imagen
      this.currentProject = project;
      this.showModal = true;
    },

    // Método para manejar la actualización (nombre o imagen)
    async handleUpdate() {
      if (this.updateType === 'name') {
        await this.updateProjectName();
      } else if (this.updateType === 'image') {
        await this.updateProjectImage();
      }
    },
    
    closeModal() {
      this.showModal = false;
      this.imageFile = null
    },
    handleFileUpload(event) {
      this.imageFile = event.target.files[0];
    },

    async insertProject() {
      let imageUrl;
    
      if (this.imageFile) {
        imageUrl = await this.uploadProjectImage(this.imageFile);
      } else {
        imageUrl = noImage
      }
      const newProject = { name: this.newProjectName, category: this.selectedCategory, imageUrl };
      if (newProject.name !== '' && newProject.category !== '') {
        this.show = true
        try {
          const projectId = await this.createProject(newProject);
          const { id } = projectId;
          this.selectProjectAndGoToCanvas(id);
          this.closeModal();
        } catch (error) {
          this.show = false;
          console.error('Error creating project:', error);
        }
      } else {
        alert('Debes llenar los campos');
      }
    },
    
    async updateProjectName() {
      if (this.currentProject) {
        const updateData = { name: this.newProjectName };
        this.show = true
        try {
          await this.updateProject({
            projectId: this.currentProject.id,
            updateData
          });
          this.show = false
          this.closeModal();
        } catch (error) {
          this.show = false
          console.error('Error updating project name:', error);
        }
      }
    },
    async updateProjectImage() {
      if (this.imageFile) {
        this.show = true
        try {
          const imageUrl = await this.uploadProjectImage(this.imageFile);
          const updateData = {
            imageUrl: imageUrl
          };
          await this.updateProject({ projectId: this.currentProject.id, updateData });
          this.show = false
          this.closeModal();
        } catch (error) {
          this.show = false
          console.error('Error updating image:', error);
        }
      } else {
        alert('Debes seleccionar una imagen a actualizar')
      }
    },
    selectProjectAndGoToCanvas(projectId) {
      this.selectProject(projectId);
      this.goToCanvas(projectId);
    },
    goToCanvas(id) {
      this.$router.push({ name: 'blank', params: { id: id } });
    },
    navigateToAllProjects() {
      this.$router.push({ name: "all-projects" });
    },
    navigateToCreateProject(projectType) {
      this.$router.push({ name: 'create-project', params: { projectType } });
    },
    async removeProject(projectId) {
      if (confirm('¿Estás seguro de que deseas eliminar este proyecto?')) {
        try {
          await this.deleteProject(projectId);
        } catch (error) {
          console.error('Error deleting project:', error);
        }
      }
    },
  },
  async created() {
    if (this.isAuthenticated) {
      // Show the spinner
      this.show = true;
      try {
        await this.fetchProjects();
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        // Hide the spinner
        this.show = false; 
      }
    } else {
      console.error('No user ID found.');
    }
  }
};
</script>
<style scoped>
/* Estilos específicos para Home.vue */
.btn-custom {
  background-color: #76b8f1;
  border: 0;
  color: #fff;
  cursor: pointer;
}
.card-category {
  border: 2px solid #76b8f1;
}
.text-plus {
  font-size: 68px;
}
.section {
  cursor: pointer;
}
.btn:focus, .btn.focus {
  box-shadow: 0 0 0 0;
}
.card-container{
  border-radius: 25px;
}
.card-container:hover {  
  background-color: rgba(118, 184, 241, 1); 
  opacity: 1;
  transition: background-color 0.3s;
}
input {
  font-size: 14px;
}
input::placeholder {
  font-size: 14px;
}

select {
  font-size: 14px;
}

select option {
  font-size: 14px;
}
.context-button {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: auto;
  min-width: 0;
}
.btn:focus, .btn.focus {
  outline: 0;
  /* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
}
.card-container:hover img[src*="no-image"] {
  filter: brightness(0) invert(1) sepia(1) hue-rotate(180deg);
}
.card-container:hover img:not([src*="no-image"]) {
  filter: none;
}
.project-img-container {
  width: 100%;
  height: 140px; 
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-img-default {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; 
}
</style>