<template>
  <b-dropdown
    id="dropdown-right"
    size="lg"
    right
    text="Right align"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
  >
    <template #button-content>
      <img
        src="@/assets/imgs/user/user.png"
        class="img-fluid img-user"
        alt="Lang icon"
        width="35"
      />
    </template>
    <b-dropdown-text>
      <div class="workspace-user-info user-profile__profile">
        <div class="base-picture user-picture-class workspace-user-info__picture base-picture-size36">
          <img alt="Img profile" src="@/assets/imgs/user/user.png" />
        </div>
        <div class="workspace-user-info__info">
          <div class="workspace-user-info__name">
            {{ user.name ?? user.displayName }}
          </div>
          <div class="workspace-user-info__email">
            {{ user.email }}
          </div>
        </div>
      </div>
    </b-dropdown-text>
    <b-dropdown-item-button @click="navigateProject">Mis proyectos</b-dropdown-item-button>
    <b-dropdown-item-button @click="onLogout">Cerrar sesión</b-dropdown-item-button>
  </b-dropdown>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Card from "@/components/shared/Card.vue";
import Swal from "sweetalert2";
export default {
  components: {
    Card,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      show: false,
      isEditing: false,
      name: "",
      email: "",
    };
  },
  async mounted() {
    await this.fetchUser();
  },
  watch: {
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.name = newValue.name ?? newValue.displayName;
          this.email = newValue.email;
        } else {
          this.name = "";
          this.email = "";
        }
      },
    },
  },
  methods: {
    ...mapActions("auth", ["fetchUser", "logout"]),
    async onLogout() {
      const result = await Swal.fire({
        title: this.$t("logout.title"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "secondary",
        confirmButtonText: this.$t("logout.yes"),
        cancelButtonText: this.$t("logout.no"),
      });

      if (result.isConfirmed) {
        try {
          await this.logout();
          this.$router.push(`/${this.$i18n.locale}/principal-page`);
        } catch (error) {
          console.error("Failed to logout:", error);
          Swal.fire(
            "Error",
            "Hubo un problema al cerrar la sesión. Por favor, inténtalo de nuevo.",
            "error"
          );
        }
      }
    },
    navigateProject() {
      const path = `/${this.$i18n.locale}/projects`;
      if (this.$route.path !== path) this.$router.push(path);
    },
  },
};
</script>
<style scoped>
.user-profile__profile {
  margin: 0 -2px;
}

.workspace-user-info {
  display: flex;
  align-items: center;
}
.base-picture.base-picture-size36 {
  width: 36px;
  height: 36px;
}

.workspace-user-info__picture {
  flex-shrink: 0;
  margin-right: 14px;
}

.user-picture-class {
  border-radius: 125px;
}

.base-picture {
  overflow: hidden;
  position: relative;
  width: 30px;
  height: 30px;
}
.base-picture.base-picture-size36 img {
  width: 36px;
  height: 36px;
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  overflow: hidden;
}
.user-profile__profile .workspace-user-info__name {
  font-size: 18px;
  font-weight: bold;
}
.workspace-user-info__email {
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
.img-user {
  filter: invert(100%);
}
</style>
