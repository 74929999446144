import componentService from '../../businessLogic/componentService';

const state = {
  components: [],
  filteredComponents: [],
};

const getters = {
  components: (state) => state.components,
  filteredComponents: state => state.filteredComponents,

};

const mutations = {
  setComponents(state, components) {
    state.components = components;
  },
  addComponent(state, component) {
    state.components.push(component);
  },
  updateComponent(state, updatedComponent) {
    const index = state.components.findIndex(comp => comp.id === updatedComponent.id);
    if (index !== -1) {
      state.components.splice(index, 1, updatedComponent);
    }
  },
  removeComponent(state, componentId) {
    state.components = state.components.filter(comp => comp.id !== componentId);
  },
  setFilteredComponents(state, filteredComponents) {
    state.filteredComponents = filteredComponents;
  },
};

const actions = {
  async fetchComponents({ commit }) {
    const components = await componentService.getAllComponents();
    commit('setComponents', components);
    commit('setFilteredComponents', components);
  },
  async addComponent({ commit }, component) {
    const componentId = await componentService.createComponent(component);
    commit('addComponent', { id: componentId, ...component });
  },
  async updateComponent({ commit }, updatedComponent) {
    await componentService.updateComponent(updatedComponent.id, updatedComponent);
    commit('updateComponent', updatedComponent);
  },
  async deleteComponent({ commit, state }, componentId) {
    const component = state.components.find(comp => comp.id === componentId);
    if (component) {
      await componentService.deleteComponent(componentId, component.imageUrl);
      commit('removeComponent', componentId);
    }
  },
  async uploadImage(_, file) {
    const url = await componentService.uploadImage(file);
    return url;
  },
  filterComponents({ commit, state }, filter) {
    const normalizedFilter = filter.toLowerCase().trim();
    if (!normalizedFilter) {
      commit('setFilteredComponents', state.components);
    } else {
      const filterComponents = state.components.filter(project =>
        project.name.toLowerCase().includes(normalizedFilter)
      );
      commit('setFilteredComponents', filterComponents);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
