import { addDoc, collection, deleteDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import { db, storage } from './firebaseConfig';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'; 

const componentData = {
  getComponents: async () => {
    const querySnapshot = await getDocs(collection(db, 'components'));
    const components = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return components;
  },

  addComponent: async (component) => {
    const docRef = await addDoc(collection(db, 'components'), component);
    const docSnap = await getDoc(docRef);
    return { id: docSnap.id, ...docSnap.data() };
  },
  async updateComponent(componentId, updatedComponent) {
    await db.collection('components').doc(componentId).update(updatedComponent);
  },

  async deleteComponent(id, imageUrl) {
    const docRef = doc(db, 'components', id);
    await deleteDoc(docRef);

    if (imageUrl) {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
    }
  },

  async uploadImage(file) {
    const storageRef = ref(storage, `images/${file.name}`)
    await uploadBytes(storageRef, file)
    const url = await getDownloadURL(storageRef)
    return url
  }
};

export default componentData;
