import componentData from '../data/componentData';

const componentService = {
  getAllComponents: async () => {
    try {
      const components = await componentData.getComponents();
      return components;
    } catch (error) {
      console.error('Error getting components:', error);
      throw error;
    }
  },
  createComponent: async (component) => {
    try {
      const componentId = await componentData.addComponent(component);
      return componentId;
    } catch (error) {
      console.error('Error creating component:', error);
      throw error;
    }
  },
  async updateComponent(componentId, updatedComponent) {
    await componentData.updateComponent(componentId, updatedComponent);
  },

  async deleteComponent(componentId) {
    await componentData.deleteComponent(componentId);
  },

  async uploadImage(file) {
    const url = await componentData.uploadImage(file);
    return url;
  }
};

export default componentService;
