// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const env = process.env.VUE_APP_ENV || 'test';

const firebaseConfigByEnv = {
  production: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY_PROD,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN_PROD,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID_PROD,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET_PROD,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID_PROD,
    appId: process.env.VUE_APP_FIREBASE_APP_ID_PROD,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID_PROD
  },
  test: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY_TEST,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN_TEST,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID_TEST,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET_TEST,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID_TEST,
    appId: process.env.VUE_APP_FIREBASE_APP_ID_TEST,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID_TEST
  }
};
    
const firebaseConfig = firebaseConfigByEnv[env];
    
if (!firebaseConfig) {
  throw new Error(`Unknown environment: ${env}`);
}
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
export { db, auth, storage };