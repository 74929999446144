import { extend, configure } from "vee-validate";
import { required, email, min, max, } from "vee-validate/dist/rules";
import { i18n } from "./i18n";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  }
});

// Install required rule and message.
extend("required", required);

// Install email rule and message.
extend("email", email);

// Install min rule and message.
extend("min", min);
// Install max rule and message.
extend("max", max);

// Agregar regla personalizada para validar contraseña
extend('password', {
  validate: (value) => {
    // Expresión regular personalizada aquí
    const regex = /^(?=.*\d)(?=.*[!@#$%^&*_.,+-_/^~])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return regex.test(value);
  },
  message: (field, values) => {
    return i18n.t('password-validation', values);
  },
});
