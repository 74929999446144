import { registerUser, loginUser, logoutUser, currentUser, loginUserWithGoogle } from '../data/authData';

const authService = {
  signInWithGoogle: async () => {
    try {
      const user = await loginUserWithGoogle();
      return user;
    } catch (error) {
      console.error('Error signing in with Google:', error);
      if (error.code === 'auth/popup-closed-by-user') {
        throw new Error('The sign-in popup was closed before completing the sign-in. Please try again.');
      }
    }
  },
  register: async (email, password, userData) => {
    try {
      const user = await registerUser(email, password, userData);
      return user;
    } catch (error) {
      console.error('Error registering user:', error);
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('The email address is already in use by another account.');
      } 
    }
  },
  login: async (email, password) => {
    try {
      const user = await loginUser(email, password);
      return user;
    } catch (error) {
      console.error('Error logging in user:', error);
      // Manejador de errores de firebase
      if (error.code === 'auth/user-not-found') {
        throw new Error('No user found with this email.');
      } 
      if (error.code === 'auth/invalid-credential') {
        throw new Error('Invalid Login Credentials.');
      } 
      throw new Error('Login failed. Please try again.');
    }
  },
  logout: async () => {
    try {
      await logoutUser();
    } catch (error) {
      console.error('Error logging out user:', error);
      throw error;
    }
  },
  getUserProfile: async() => {
    try {
      const userProfile = await currentUser();
      return userProfile;
    } catch (error) {
      console.error('Error getting user profile:', error);
      throw error;
    }
  },
};

export default authService;
