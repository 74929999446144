import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pt from "vee-validate/dist/locale/pt_BR.json";
import es from "vee-validate/dist/locale/es.json";
Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

const messages = {
  ...loadLocaleMessages(),
  // Traducciones de VeeValidate
  pt: {
    ...loadLocaleMessages().pt,
    fields: {
      email: "e-mail",
      password: "Senha",
      name: "Nome",
      occupation: "Ocupação",
      category: "Categoria"
    },
    validation: pt.messages
  },
  es: {
    ...loadLocaleMessages().es,
    fields: {
      email: "Correo Electronico",
      password: "Contraseña",
      name: "Nombre",
      occupation: "Ocupación",
      category: "Categoría"
    },
    validation: es.messages
  }
};

const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages
})

export { i18n }