<template>
  <blockquote class="blockquote">
    <div class="d-flex justify-content-between">
      <div class="ml-md-5">
        <img src="@/assets/imgs/icon-quotation.png" alt="Img Cita" height="80" style="margin-top: -37px;" />
      </div>
      <p class="text-right phrase">
        {{ $t('blockquote.paragraph') }}
      </p>
    </div>
    <p class="d-flex justify-content-end my-4">- Eleanor Roosevelt</p>
  </blockquote>
</template>
<script>
export default {
  name: "Blockquote",
}
</script>

<style scoped>
.phrase {
  font-size: 24px;
  line-height: 1;
}
</style>