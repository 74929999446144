import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth"; 
import components from "./modules/components"; 
import projects from "./modules/projects"; 
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebars: {}
  },
  getters: {

  },
  mutations: {
    toggleSidebar(state, id) {
      // Si el sidebar con este ID no existe, lo creamos y lo abrimos
      if (!state.sidebars[id]) {
        state.sidebars = { ...state.sidebars, [id]: true };
      } else {
        // Si ya existe, invertimos su estado
        state.sidebars[id] = !state.sidebars[id];
      }
    },
    closeSidebar(state, id) {
      if (state.sidebars[id]) {
        state.sidebars[id] = false;
      }
    }
  },
  actions: {

  },
  modules: {
    auth,
    components,
    projects
  },
});
