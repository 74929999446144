<template>
  <b-overlay :show="show" rounded="sm" class="d-flex align-items-center justify-content-center">
    <b-container >
      <b-row>
        <b-col cols="12" md="6" class="my-3"  >
        <!-- Contenido de la primera columna -->
          <b-card class="pl-md-3 pr-md-3 ">
            <b-col>
            <b-card-title>{{ $t('login.title') }}</b-card-title>
            </b-col>
            <b-col class="my-3">
              <b-card-text class="d-inline-block " style="font-size: 14px;">
                {{ $t('login.text') }}
                <b-link :to="`/${$i18n.locale}/principal-page/register`"> {{ $t('login.link') }} </b-link>
              </b-card-text>
            </b-col>
            <b-col >
              <ValidationObserver v-slot="{ invalid, handleSubmit }">
                <b-form @submit.prevent="handleSubmit(makeLogin)">
                  <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                    <b-form-group :label="$t('login.label-email')" label-for="correo" style="font-size: 14px;">
                      <b-form-input id="correo" v-model="email" trim class="input-custom"
                        :class="{ 'is-invalid': errors[0] }"></b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="password" rules="required|max:50" v-slot="{ errors }">
                    <b-form-group :label="$t('login.label-pass')" label-for="password" style="font-size: 14px;"  class="inner-addon right-addon">
                      <b-form-input id="password" v-model="password" trim class="input-custom"
                        :class="{ 'is-invalid': errors[0] }" :type="showPassword ? 'text' : 'password'"></b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                      <b-input-group-append>
                        <span @click="showPassword = !showPassword" class="left-addon right-addon far fas eye-show" :class="{ hide: showPassword }">
                          <eye-icon />
                        </span>
                        <span class="left-addon right-addon far fas eye-hide" @click="showPassword = !showPassword" :class="{ hide: !showPassword }">
                          <eye-off-icon />
                        </span>
                      </b-input-group-append>
                    </b-form-group>
                  </ValidationProvider>
                  <div class="d-flex justify-content-end">
                    <b-button
                      type="submit"
                      variant="primary"
                      :disabled="invalid"
                    >{{ $t('btn') }}
                    </b-button>
                  </div>
                  <b-card-text class="text-center my-3"> {{ $t('option') }} </b-card-text>
                </b-form>
              </ValidationObserver>     
            </b-col>     
            <b-col cols="12" class="d-flex justify-content-center">
              <!-- <b-button block class="b-button" style="background-color: #1A4789;" disabled>
                <img src="@/assets/icons/icons-facebook.svg" alt="Icono de Facebook" class="img-fluid" width="38" />
                <span class="mx-auto">{{ $t('btn-fb') }}</span>
              </b-button> -->
              <b-button block class="b-button text-dark" style="background-color: #fff;" @click.prevent="loginWithGoogle">
                <img src="@/assets/icons/icons-google.svg" alt="Icono de Google" class="img-fluid" width="38" />
                <span class="mx-auto">{{ $t('btn-google') }}</span>
              </b-button>
            </b-col>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" class="my-3">
          <!-- Contenido de la segunda columna -->
          <div class="ml-md-5 ml-lg-5">
            <Card :customClass="'shadow custom-card-login'" >
              <!-- <img src="@/assets/gifs/img_signin-signup.gif" class="img-fluid" width="342" alt="Robot animate"> -->
              <img src="@/assets/gifs/img_signin-signup.gif" class="mx-auto d-block" width="300" height="250" alt="Robot animate">
            </Card>
          </div>
          <div class="mt-5">
            <Blockquote></Blockquote>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>
<script>
import EyeIcon from "vue-material-design-icons/Eye.vue";
import EyeOffIcon from "vue-material-design-icons/EyeOff.vue";
import Swal from 'sweetalert2';
import Card from '@/components/shared/Card.vue';
import Blockquote from '@/components/shared/Blockquote.vue';
import { mapActions } from "vuex";
export default {
  components: {
    Card,
    Blockquote,
    EyeIcon,
    EyeOffIcon
  },
  data() {
    return {
      show: false,
      email: "",
      password: "",
      showPassword: false,
    }
  },
  methods: {
    ...mapActions('auth', ['login','signInWithGoogle']),
    async loginWithGoogle() {
      try {
        await this.signInWithGoogle();
        this.$router.push(`/${this.$i18n.locale}/home`)
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Login With Google Failed',
          text: error.message,
        });
      }
    },
    async makeLogin() {
      this.show = true;
      try {
        await this.login({ email: this.email, password: this.password });
        this.$router.push(`/${this.$i18n.locale}/home`)
      } catch (error) {
        this.show = false;
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: error.message,
        });
      }
    },
  },
}
</script>
<style scoped>
.input-custom {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
}

.b-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 345px;
}
.btn-secondary {
  border-color: #eee;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  border-color: #eee;
}

.hide {
  display: none;
}
.inner-addon {
  position: relative;
}
.inner-addon .far,
.inner-addon .fas {
  position: absolute;
  padding: 9px 6px;
  z-index: 9;
  cursor: pointer;
}
.left-addon .far,
.left-addon .fas {
  left: 0px;
}
.right-addon .far,
.right-addon .fas {
  right: 0px;
  top: 25px;
}
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}
input {
  font-size: 14px;
}
input::placeholder {
  font-size: 14px;
}
</style>

