import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut,
  GoogleAuthProvider, 
  signInWithPopup,
} from 'firebase/auth';

const googleProvider = new GoogleAuthProvider();

const loginUserWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const token = result.credential?.accessToken;

      const user = result.user;
      const uid = user.uid
      const userDoc = await getDoc(doc(db, "users", uid));
        
      // Save user data to Firestore if not already saved
      if (!userDoc.exists()) {
        await setDoc(doc(db, "users", uid), {
          email: user.email,
          displayName: user.displayName,
          uid
        });
      }
      return {
        email: user.email,
        displayName: user.displayName,
        uid
      };
    } catch (error) {
      console.error('Error signing in with Google:', error);
      throw error;
    }
};
  
const registerUser = async (email, password, userData) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password)
  const uid = userCredential.user.uid
  await setDoc(doc(db, 'users', uid), {...userData, uid});
  return {
    email: userCredential.user.email,
    name: userData.name, // field firestore (users)
    uid
  };
};
  
const loginUser = async (email, password) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const uid = userCredential.user.uid;
  const userDoc = await getDoc(doc(db, 'users', uid));

  if (userDoc.exists()) {
    const userData = userDoc.data();
    return {
      email: userCredential.user.email,
      name: userData.name,
      uid
    };
  } else {
    throw new Error('User data not found in Firestore');
  }
};
  
const logoutUser = async () => {
  await signOut(auth);
};

const currentUser = async() => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    return userDoc.data();
  }
  return null;
}

export { registerUser, loginUser, logoutUser, currentUser, loginUserWithGoogle };