<template>
  <b-card :class="customClass" :style="customStyle" class="custom-card">
    <div class="container justify-content-center text-center">
      <slot></slot>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "Card",
  props: {
    customClass: {
      type: String,
      default: '' // Por defecto, la tarjeta no tendrá clases personalizadas
    },
    customStyle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.custom-card {
  border-radius: 1.25rem;
}
</style>