import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from './firebaseConfig';
import { 
  collection, 
  getDocs, 
  addDoc, 
  query, 
  where, 
  getDoc, 
  doc, 
  deleteDoc, 
  updateDoc, 
  arrayUnion
} from 'firebase/firestore';

const projectData = {
  getProjectsByUserId: async (userId) => {
    const q = query(collection(db, 'projects'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const projects = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return projects;
  },
  createProject: async (projectData) => {
    const docRef = await addDoc(collection(db, 'projects'), projectData);
    const docSnap = await getDoc(docRef);
    return { id: docSnap.id, ...docSnap.data() };
  },
  deleteProject: async (projectId) => {
    const projectRef = doc(db, 'projects', projectId);
    const projectSnapshot = await getDoc(projectRef);
  
    if (projectSnapshot.exists()) {
      const projectData = projectSnapshot.data();
  
      // Solo intenta eliminar la imagen del Storage si no es la imagen por defecto
      if (projectData.imageUrl && !projectData.imageUrl.includes('no-image')) {
        const imageRef = ref(storage, projectData.imageUrl);
        try {
          await deleteObject(imageRef);
        } catch (error) {
          console.error('Error deleting image from storage:', error);
        }
      }
  
      // Elimina el documento del proyecto en Firestore
      await deleteDoc(projectRef);
    } 
  },
  updateProject: async (projectId, updateData) => {
    const projectRef = doc(db, 'projects', projectId);
    await updateDoc(projectRef, updateData);
    const updatedDoc = await getDoc(projectRef);
    return { id: updatedDoc.id, ...updatedDoc.data() };
  },
  getProjectById: async (userId, projectId) => {
    const projectRef = doc(db, 'projects', projectId);
    const projectSnap = await getDoc(projectRef);
    if (projectSnap.exists() && projectSnap.data().userId === userId) {
      return { id: projectSnap.id, ...projectSnap.data() };
    } else {
      throw new Error('Project not found or does not belong to this user');
    }
  },
  addComponentToProject: async (projectId, component) => {
    const projectRef = doc(db, 'projects', projectId);
    await updateDoc(projectRef, {
      components: arrayUnion(component)
    });
  },
  removeComponentFromProject: async (projectId, idLocal) => {
    const projectRef = doc(db, 'projects', projectId);
    // Obtener el proyecto completo para eliminar solo el componente deseado
    const projectSnap = await getDoc(projectRef);
    if (projectSnap.exists()) {
      const projectData = projectSnap.data();
      const updatedComponents = projectData.components.filter(component => component.id_local !== idLocal);

      await updateDoc(projectRef, {
        components: updatedComponents
      });
    } else {
      console.error('Project not found');
    }
  },
  uploadImageToProject: async (file) => {
    const allowedTypes = ['image/png', 'image/jpeg'];
    // Verifica si el archivo es de un tipo permitido
    if (!allowedTypes.includes(file.type)) {
      throw new Error('El archivo debe ser una imagen en formato png o jpg');
    }
    const timestamp = Date.now();
    const sanitizedFileName = `${timestamp}_${file.name.replace(/[^a-zA-Z0-9.-]/g, '_')}`;
    const storageRef = ref(storage, `project/${sanitizedFileName}`);
  
    // Subir el archivo
    await uploadBytes(storageRef, file);
  
    const url = await getDownloadURL(storageRef);
    return url;
  },
};

export default projectData;