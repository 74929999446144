<template>
  <footer class="footer">
    <p class="footer-text">Copyright &copy; {{ currentYear }} Inventech</p>
  </footer>
</template>
  
<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
  
<style scoped>
.footer {
  height: 52px;
  background-color: #0B1427;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-text {
  margin: 0;
}

</style>
  