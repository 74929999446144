import projectService from '../../businessLogic/projectService';

const state = {
  projects: [],
  filteredProjects: [],
  selectedProject: null,
};

const getters = {
  projects: (state) => state.projects,
  filteredProjects: state => state.filteredProjects,
  selectedProject: state => state.selectedProject,
};

const mutations = {
  setProjects(state, projects) {
    state.projects = projects;
  },
  updateProject(state, updatedProject) {
    const updatedProjects = state.projects.map(proj =>
      proj.id === updatedProject.id ? updatedProject : proj
    );
    state.projects = updatedProjects;
    if (state.selectedProject && state.selectedProject.id === updatedProject.id) {
      state.selectedProject = updatedProject;
    }
  },
  removeProject(state, projectId) {
    state.projects = state.projects.filter(proj => proj.id !== projectId);
  },
  setFilteredProjects(state, filteredProjects) {
    state.filteredProjects = filteredProjects;
  },
  setSelectedProject(state, project) {
    state.selectedProject = project;
  },
  deselectProject(state) {
    state.selectedProject = null;
  },
  addComponent(state, { projectId, component }) {
    const project = state.projects.find(p => p.id === projectId);
    if (project) {
      if (!Array.isArray(project.components)) {
        project.components = [];
      }

      // Crea una nueva referencia y asigna el array a project.components
      project.components = [...project.components, component];

      // Actualiza selectedProject si es el proyecto seleccionado
      if (state.selectedProject && state.selectedProject.id === projectId) {
        state.selectedProject = {
          ...state.selectedProject,
          components: [...project.components]
        };
      }
    }
  },  
  removeComponent(state, idLocal) {
    if (state.selectedProject) {
      state.selectedProject.components = state.selectedProject.components.filter(component => component.id_local !== idLocal);
    }
  },
};

const actions = {
  async fetchProjects({ commit, rootState }) {
    const userId = rootState.auth.user.uid;
    const projects = await projectService.getProjectsByUserId(userId);
    commit('setProjects', projects);
    commit('setFilteredProjects', projects);
  },
  async createProject({ commit, rootGetters }, projectData) {
    try {
      projectData.userId = rootGetters['auth/user'].uid; // Añadir el userId al proyecto
      const projectId = await projectService.createProject(projectData);
      // Después de crear el proyecto, obtener la lista de proyectos nuevamente
      const projects = await projectService.getProjectsByUserId(projectData.userId);
      commit('setProjects', projects);
      return projectId; // Devuelve el ID del proyecto creado
    } catch (error) {
      console.error('Error creating project:', error);
      throw error;
    }
  },
  async updateProject({ commit }, { projectId, updateData }) {
    try {
      const updatedProject = await projectService.updateProject(projectId, updateData);
      commit('updateProject', updatedProject);
    } catch (error) {
      console.error('Error updating project:', error);
      throw error;
    }
  },
  async deleteProject({ commit }, projectId) {
    try {
      await projectService.deleteProject(projectId);
      commit('removeProject', projectId);
    } catch (error) {
      console.error('Error deleting project:', error);
      throw error;
    }
  },
  filterProjects({ commit, state }, filter) {
    const normalizedFilter = filter.toLowerCase().trim();
    if (!normalizedFilter) {
      commit('setFilteredProjects', state.projects);
    } else {
      const filteredProjects = state.projects.filter(project =>
        project.name.toLowerCase().includes(normalizedFilter)
      );
      commit('setFilteredProjects', filteredProjects);
    }
  },
  selectProject({ commit, dispatch }, projectId) {
    dispatch('fetchProjectById', projectId);
  },
  deselectProject({ commit }) {
    commit('deselectProject');
  },
  async fetchProjectById({ commit, rootState }, projectId) {
    try {
      const userId = rootState.auth.user.uid;
      const project = await projectService.getProjectById(userId, projectId);
      commit('setSelectedProject', project);
    } catch (error) {
      console.error('Error fetching project by ID:', error);
    }
  },
  async countComponentsByPrefix({ commit }, { userId, projectId, prefix }) {
    try {
      const count = await projectService.countComponentsByPrefix(userId, projectId, prefix);
      return count;
    } catch (error) {
      console.error('Error counting components by prefix:', error);
      throw error;
    }
  },
  async addComponentToProject({ commit }, { projectId, component }) {
    try {
      await projectService.addComponentToProject(projectId, component);
      commit('addComponent', { projectId, component });
    } catch (error) {
      console.error('Error adding component to project:', error); 
      throw error;
    }
  },
  async removeComponentFromProject({ commit }, { projectId, idLocal }) {
    try {
      await projectService.removeComponentFromProject(projectId, idLocal);
      commit('removeComponent', idLocal);
    } catch (error) {
      console.error('Error removing component from project:', error);
    }
  },
  async uploadProjectImage(_, file) {
    const url = await projectService.uploadImageToProject(file);
    return url;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
