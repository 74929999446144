import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PrincipalPage from '../components/principal-page/PrincipalPage.vue'
import RegisterPrincipal from '../components/principal-page/session/RegisterPrincipal.vue'
import LoginPrincipal from '../components/principal-page/session/LoginPrincipal.vue'
import CreateProject from "@/views/CreateProject.vue";
import { i18n } from '../i18n'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}/principal-page`
  },
  {
    path: "/:lang",
    component: {
      render(c) { return c('router-view')}
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView,
        meta: { requiresAuth: true },
      },
      {
        path: "create-project/:projectType", // Define la URL que activará la vista de creación de proyectos
        name: "create-project",
        component: CreateProject,
        meta: { requiresAuth: true },
      },
      {
        path: 'board/:id',
        name: 'blank',
        component: () => import('../views/NewProjectBlank.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'projects',
        name: 'all-projects',
        component: () => import('../views/projects/MyProject.vue'),
        meta: { requiresAuth: true },
      },
      {
        path:'principal-page',
        name: 'principal-page',
        component: PrincipalPage
      },
      {
        path:'principal-page/register',
        name: 'register-principal',
        component: RegisterPrincipal,
      },
      {
        path:'principal-page/login',
        name: 'login-principal',
        component: LoginPrincipal,
      },
      {
        path: '*',
        redirect: `/${i18n.locale}/principal-page`
      }
    ]
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// Define una lista de idiomas válidos
const validLanguages = ['pt', 'es'];

// Agrega una guarda de navegación en Vue Router
router.beforeEach((to, from, next) => {
  // Obtiene el idioma de la ruta o establece uno predeterminado si no se proporciona
  let language = to.params.lang || 'es';

  // Valida si el idioma es válido
  if (validLanguages.includes(language)) {
    // Si es válido, establece el idioma en i18n
    i18n.locale = language;
    next();
  } else {
    // Si el idioma no es válido, redirige a la ruta predeterminada
    next(`/${i18n.locale}${to.path}`);
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = store.getters['auth/isAuthenticated']
  if (to.path === `/${i18n.locale}/principal-page/login` && user) {
    next(`/${i18n.locale}/home`)
  } else if (requiresAuth && !user) {
    next(`/${i18n.locale}/principal-page/login`)
  } else {
    next()
  }
});


export default router;